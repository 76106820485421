<template>
  <div class="pageContol listWrap templateList addClassCtr">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <i>></i>
          <a href="javascript:;" class="cur-a">机构信息</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div style="display:flex;justify-content: flex-end;">
              <el-button
                size="mini"
                class="bgc-bv"
                v-if="disabled"
                @click="doEdit"
                >编辑</el-button
              >
            </div>
            <h3 style="padding-bottom:20px;">基本信息</h3>
            <el-form
              ref="Form"
              :model="Form"
              :rules="rules"
              :disabled="disabled"
              label-width="130px"
            >
              <el-form-item label="机构名称" prop="compName" class="form-item">
                <el-input
                  v-model="Form.compName"
                  placeholder="请输入机构名称"
                  maxlength="50"
                  show-word-limit
                  size="small"
                  clearable
                />
              </el-form-item>
              <el-form-item
                label="所属行政区划："
                prop="areaId"
                class="form-item"
              >
                <el-cascader
                  v-model="Form.areaId"
                  clearable
                  filterable
                  :options="areatreeList"
                  :props="propsarea"
                  @change="areaChange"
                  size="small"
                ></el-cascader>
              </el-form-item>

              <el-form-item
                label="社会信用代码："
                prop="compNo"
                class="form-item"
              >
                <el-input
                  v-model="Form.compNo"
                  placeholder="请输入社会信用代码"
                  size="small"
                  clearable
                />
              </el-form-item>
              <el-form-item label="联系人：" prop="compUser" class="form-item">
                <el-input
                  v-model="Form.compUser"
                  placeholder="请输入联系人"
                  size="small"
                  clearable
                />
              </el-form-item>
              <el-form-item
                label="所属行业："
                prop="compIndustry"
                class="form-item"
              >
                <el-select
                  v-model="Form.compIndustry"
                  placeholder="请选择"
                  size="small"
                  clearable
                >
                  <el-option
                    v-for="item in IndustrySelectData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="联系人电话："
                prop="compPhone"
                class="form-item"
              >
                <el-input
                  v-model="Form.compPhone"
                  placeholder="请输入联系人电话"
                  maxlength="12"
                  size="small"
                  clearable
                />
              </el-form-item>
              <el-form-item label="地址" prop="compBusiness" class="form-item">
                <el-input
                  type="text"
                  v-model="Form.compBusiness"
                  size="small"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item
                label="营业执照"
                prop="compLicense"
                class="form-item"
              >
                <el-upload
                  :on-change="handleAvatarSuccess2"
                  :before-upload="beforeAvatarUpload2"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="Form.compLicense || require('@/assets/develop.png')"
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
                <p style="color:#f46173">
                  请上传640*375（长*宽）像素的PNG、JPG格式的图片，且文件大小不超过1MB！
                </p>
              </el-form-item>
            </el-form>
            <div class="bottom-btn" v-show="!disabled">
              <el-button @click="cancelData()" class="bgc-bv">取消</el-button>
              <el-button @click="seveData()" class="bgc-bv">保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "hospital/InstitutionalInformation",
  components: {},
  mixins: [UploadPic, DatePicker, List],
  data() {
    //信用代码
    var creditCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入社会信用代码"));
      } else if (
        value &&
        !/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/g.test(
          value
        )
      ) {
        callback(new Error("请输入正确的社会信用代码"));
      } else {
        callback();
      }
    };
    var projectUserPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入正确的联系人电话"));
      } else if (
        value &&
        !/^1[3456789]\d{9}$/.test(value) &&
        !/^([0-9]{3,4}-)?[0-9]{7,8}$/.test(value)
      ) {
        callback(new Error("请输入正确的联系人电话"));
      } else {
        callback();
      }
    };
    return {
      //所属行业下拉数据
      IndustrySelectData: [],
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      Form: {
        compName: "", //机构名称
        areaId: "", //所属行政区划id
        compNo: "", //社会信用代码
        compUser: "", //联系人
        compPhone: "", //联系人电话
        compIndustry: "", //所属行业
        compBusiness: "", //地址
        compLicense: "",
        fileKey: "",
      },
      rules: {
        compName: [
          { required: true, message: "请输入机构名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" },
        ],
        compUser: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        compNo: [{ required: true, trigger: "blur", validator: creditCode }],
        compPhone: [
          { required: true, validator: projectUserPhone, trigger: "blur" },
        ],
        compIndustry: [
          { required: true, message: "请选择所属行业", trigger: "blur" },
        ],
        // email: [{ required: false, validator: checkEmail, trigger: "blur" }]
      },
      disabled: true,
    };
  },
  created() {
    this.disabled = true;
    this.getInfoMation();
    this.getIndustry();
    this.getareatree();
  },
  methods: {
    //机构信息--回显数据
    getInfoMation() {
      this.$post("/sys/company/getInfo").then((ret) => {
        let datas = ret.data || {};
        console.log(datas);
        this.Form = {
          compName: datas.compName, //机构名称
          areaId: datas.areaId, //所属行政区划id
          compNo: datas.compNo, //社会信用代码
          compUser: datas.compUser, //联系人
          compPhone: datas.compPhone, //联系人电话
          compIndustry: datas.compIndustry, //所属行业
          compBusiness: datas.compBusiness, //地址
          compLicense: datas.compLicenseUrl,
          fileKey: datas.compLicense,
        };
      });
    },
    //编辑
    doEdit() {
      this.disabled = false;
    },
    //获取所属行业--码值
    getIndustry() {
      const Industry = this.$setDictionary("INDUSTRY", "list");
      for (const key in Industry) {
        this.IndustrySelectData.push({
          value: key,
          label: Industry[key],
        });
      }
    },
    //上传图片
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.Form.compLicense = result.data.fileURL;
            this.Form.fileKey = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    //保存
    seveData(ruleForm) {
      const el = this.$refs.Form;
      el.validate((valid) => {
        if (valid) {
          this.doAjax();
        }
      });
    },
    // 添加
    doAjax() {
      let parmar = {
        compName: this.Form.compName,
        areaId: this.Form.areaId,
        compNo: this.Form.compNo,
        compIndustry: this.Form.compIndustry,
        compPhone: this.Form.compPhone,
        compUser: this.Form.compUser,
        compBusiness: this.Form.compBusiness,
        compLicense: this.Form.fileKey,
      };
      this.$post("/sys/company/comp/saveOrUpdate", parmar)
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.disabled = true;
            this.getInfoMation();
          }
        })
        .catch((err) => {
          return;
        });
    },
    cancelData() {
      this.disabled = true;
      this.$refs["Form"].resetFields();
      this.getInfoMation();
    },
  },
};
</script>
<style lang="less">
.addClassCtr {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 45%;
    }
  }
  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
